<template>
  <div class="mt-4">
    <LoadingButton class-name="btn btn-primary" text="New Category" @on-click="$router.push({ name: 'Create new Category'})"/>
    <div class="card shadow mt-2">
      <div class="card-body">
        <div class="row">
          <dic class="col-md-12">
              <div
                class="tab-pane fade show active"
                id="tabs-icons-text-1"
                role="tabpanel"
                aria-labelledby="tabs-icons-text-1-tab"
              >
                <data-table
                  v-if="data_loaded"
                  id="parent_categories"
                  :options="dtb_options.extra_options"
                  :actions="dtb_options.actions"
                  :data="dtb_options.data"
                  :columns="dtb_options.columns"
                />
              </div>
          </dic>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DataTable from "@/components/Datatable";
import LoadingButton from "@/components/Buttons/LoadingButton";
export default {
  name: "Entities",
  components: {
    LoadingButton,
    "data-table": DataTable,
  },
  data() {
    return {
      data_loaded: false,

      dtb_options: {
        data: [],
        actions: [
          {
            btnText: "Detail",
            btnClass: "btn btn-default btn-sm",
            iconClass: "fa fa-info-circle",
            btnFn: (uuid) => {
              this.$router.push({ name: "Manage Category", params: { uuid } });
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          { title: "English", data: "name" },
          { title: "Kinyarwanda", data: "kin_name" },
        ],
        extra_options: {},
      },
    
     
    };
  },
  methods: {
    fetch_categories: function () {
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/parentCategories`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.dtb_options.data = res.data;
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.fetch_categories();
    
  },
 
};
</script>
<style lang="css" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #172a4d;
}
.nav-pills .nav-link {
  color: #172a4d;
}
.nav-pills .nav-link:hover {
  color: #172a4d;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover {
  color: white;
}
</style>